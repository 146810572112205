<template>
  <mds-table>
    <mds-tbody>
      <mds-tr
        v-for="link in links"
        :key="link.id"
      >
        <mds-td
          v-for="header in $options.headers"
          :key="header.fieldName"
          :style="header.style"
        >
          <template v-if="header.fieldName === 'lastExecutedDate'">
            {{ link[header.fieldName] | formatDate }}
          </template>
          <template v-else-if="header.fieldName === 'homePage'">
            <a
              target="_blank"
              :href="link.urlCrawled"
              v-text="link.urlCrawled"
            />
          </template>
          <template v-else-if="header.fieldName === 'downloadedFilesCount'">
            {{ link[header.fieldName] }}
          </template>
          <template v-else-if="header.fieldName === 'fileErrors'">
            {{ link | sumProviderLinksErrors }}
          </template>
          <template v-else-if="header.fieldName === 'executionCount'">
            {{ link[header.fieldName] }}
          </template>
          <template v-else-if="header.fieldName === 'newFilesFoundCount'">
            {{ link[header.fieldName] }}
          </template>
          <template v-else-if="header.fieldName === 'status'">
            <status-tag :status="link[header.fieldName]" />
          </template>
          <template v-else-if="header.fieldName === 'actions'">
            <mds-button-container>
              <router-link
                :to="{
                  name: $options.route.JOB,
                  params: { jobId: link.jobId, linkId: link.id }
                }"
              >
                <mds-button
                  variation="icon-only"
                  icon="eye"
                  type="button"
                />
              </router-link>
              <link-actions :link="link" />
            </mds-button-container>
          </template>
          <template v-else>
            {{ '—' }}
          </template>
        </mds-td>
      </mds-tr>
    </mds-tbody>
  </mds-table>
</template>

<script>
import { MdsTable, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { TABLE_HEADERS } from '@/constants/jobs.constant';
import StatusTag from '@/components/ui/StatusTag';
import { formatDate } from '@/utils/global.util';
import ROUTE from '@/constants/routes.constant';
import LinkActions from '@/components/collection/table/actions/LinkActions';

export default {
  name: 'LinksTable',
  components: {
    StatusTag,
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsButtonContainer,
    LinkActions,
  },
  filters: {
    formatDate,
    formatIndexType (index) {
      return index && index.split('_')[1];
    },
    sumProviderLinksErrors (link) {
      return link.filesFoundCount === 0 ? 0 : link.filesFoundCount - link.downloadedFilesCount;
    },
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  headers: TABLE_HEADERS,
  route: ROUTE,
};
</script>
