<template>
  <mds-button-container>
    <download-report-action :job="job" />
    <edit-job-action :job="job" />
    <schedule-now-action :job="job" />
  </mds-button-container>
</template>

<script>
import { MdsButtonContainer } from '@mds/button';
import DownloadReportAction from '@/components/collection/table/actions/DownloadReportAction';
import EditJobAction from '@/components/collection/table/actions/EditJobAction';
import ScheduleNowAction from '@/components/collection/table/actions/ScheduleNowAction';

export default {
  name: 'TableActions',
  components: {
    MdsButtonContainer,
    DownloadReportAction,
    EditJobAction,
    ScheduleNowAction,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
