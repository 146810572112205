<template>
  <div>
    <mds-button
      variation="icon-only"
      icon="triangle-fill-right"
      text="Run"
      :disabled="job.status === 'RUNNING' || job.status === 'SCHEDULED'"
      @click.stop="toggleModal"
    />
    <portal
      v-if="isModalVisible"
      to="modals"
    >
      <mds-modal
        v-model="isModalVisible"
        width="600px"
        action-required
      >
        <mds-section
          border-position="bottom"
          bold
        >
          <template #mds-section-title>
            <span id="title-id">{{ $t(`colletionPageCP.sheduleJobNowTitle`) }}</span>
          </template>
          <template #mds-section-actions>
            <mds-button-container right-aligned>
              <mds-button
                variation="secondary"
                @click="toggleModal"
              >
                Cancel
              </mds-button>
              <mds-button
                variation="primary"
                :loading="isLoading"
                :disabled="isLoading"
                @click="scheduleJobNow(job.id); toggleModal()"
              >
                Run
              </mds-button>
            </mds-button-container>
          </template>
          {{ $t(`colletionPageCP.sheduleJobNow`) }}
        </mds-section>
      </mds-modal>
    </portal>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import { scheduleJobNow } from '@/services/api/job.service';
import { formatDate } from '@/utils/global.util';

export default {
  name: 'SheduleJobNow',
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsSection,
  },
  filters: {
    formatDate,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isModalVisible: false,
      isLoading: false,
    };
  },
  methods: {
    toggleModal () {
      this.isModalVisible = !this.isModalVisible;
    },
    async scheduleJobNow (id) {
      const { data: job } = await scheduleJobNow({ id });
      this.job.status = job.status;
      this.job.nextExecutionDate = formatDate(job.nextExecutionDate);
    },
  },
};
</script>
