<template>
  <div>
    <mds-row
      align-horizontal="space-between"
      class="filter"
    >
      <mds-col
        :cols="12"
        :cols-at-s="12"
        :cols-at-m="5"
        :cols-at-l="4"
      >
        <mds-search-field
          placeholder="Search by Name or Company Id"
          class="filter__search"
          label="Search"
          @input.native="fetchJobs"
        />
      </mds-col>
      <mds-col
        :cols="12"
        :cols-at-s="12"
        :cols-at-m="7"
        :cols-at-l="5"
      >
        <mds-form @submit.native.prevent="getJobs">
          <mds-fieldset
            legend="Filter by Coverage"
            hidden-legend
            horizontal
          >
            <mds-combo-box
              v-model="coveragesSelected"
              label="Filter by Coverage"
              multiple
              class="filter__combo"
              :data-set="coverages"
              @input="setParam($event, 'coverages')"
            />
            <mds-button
              variation="secondary"
              text="Clear"
              class="filter__button"
              @click="clearParams"
            />
            <mds-button
              variation="primary"
              :disabled="coveragesSelected.length==0"
              type="submit"
              text="Search"
              class="filter__button"
            />
          </mds-fieldset>
        </mds-form>
      </mds-col>
    </mds-row>
    <mds-row
      class="filter"
    >
      <mds-col :cols="12">
        <mds-button-group
          :content="buttonGroupContent"
          class="md filter__status"
          @mds-button-group-item-active="setActiveItem"
        />
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import MdsComboBox from '@mds/combo-box';
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import { MdsButton } from '@mds/button';
import MdsButtonGroup from '@mds/button-group';
import { MODULE, COMMON } from '@/constants/store.constant';
import debounce from 'lodash.debounce';
import { getCoverage } from '@/services/api/coverage.service';

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
    MdsButtonGroup,
    MdsButton,
    MdsComboBox,
    MdsForm,
    MdsFieldset,
  },
  data () {
    return {
      buttonGroupContent: [
        { id: '0', text: 'All', value: null, active: true },
        { id: '1', text: 'Scheduled', value: 'SCHEDULED', active: false },
        { id: '2', text: 'Running', value: 'RUNNING', active: false },
        { id: '3', text: 'Completed', value: 'COMPLETED', active: false },
        { id: '4', text: 'Error', value: 'COMPLETED_WITH_ERROR', active: false },
        { id: '5', text: 'Inactive', value: 'PROVIDER_INACTIVE', active: false }
      ],
      coverages: [],
      coveragesSelected: [],
    };
  },
  params () {
    return { ...this.$route.params, type: this.linkType };
  },
  created () {
    this.getCoverages();
  },
  methods: {
    async getCoverages () {
      const { data: coverages } = await getCoverage();
      this.coverages = coverages.map((coverage) => {
        return {
          text: coverage,
          value: coverage,
        };
      });
    },
    setParam (value, name) {
      const coverages = value.filter((coverage) => (typeof coverage === 'string' || coverage instanceof String)).join(',');
      this.$store.commit(`${MODULE.COLLECTION}/${COMMON.UPDATE_PARAM}`, {
        name: name,
        value: coverages,
      });
    },
    clearParams () {
      this.$store.commit(`${MODULE.COLLECTION}/${COMMON.UPDATE_STATE}`, {
        name: 'params',
        value: {
          page: 0,
          size: 40,
        },
      });
      this.coveragesSelected = [];
      this.fetchJobs();
    },
    async getJobs () {
      await this.$store.dispatch(`${MODULE.COLLECTION}/${COMMON.FETCH_DATA}`, {
        params: this.params,
        shouldReset: true,
      });
    },
    fetchJobs: debounce(async function ({ target: { value } } = { target: '' }) {
      this.$store.commit(`${MODULE.COLLECTION}/${COMMON.UPDATE_PARAM}`, { name: 'name', value });
      await this.$store.dispatch(`${MODULE.COLLECTION}/${COMMON.FETCH_DATA}`, { shouldReset: true });
    }, 1000),
    setActiveItem ({ currentTarget: { id } }) {
      this.buttonGroupContent.forEach((item) => {
        item.active = (item.id === id);
        if (item.active) {
          this.$store.commit(`${MODULE.COLLECTION}/${COMMON.UPDATE_PARAM}`, { name: 'status', value: item.value });
          this.$store.dispatch(`${MODULE.COLLECTION}/${COMMON.FETCH_DATA}`, { shouldReset: true });
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  &__search {
    margin: 30px 0px 10px;
  }
  &__status {
    margin: 5px 5px 0;
    display: flex;
    justify-content: flex-start;
    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }
  }
  &__combo {
    padding-top: 2px;
  }
  &__button {
    margin: 30px 5px 0;
    display: block;
    max-width: 90px;
  }
}
</style>
