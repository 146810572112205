const sortDirectory = {
  desc: -1,
  asc: 1,
};

const sortDirectoryInverted = {
  '-1': 'asc',
  '1': 'desc',
  '0': 'desc',
};

/**
 * Parse sort info from string to number
 * @param {object} store store object
 * @param {string} fieldName name of columns parse sort
 * @returns {number} return one number between 0, 1, -1
 */
export const parseSorted = ({ params }, fieldName) => {
  const { sort } = params;
  const [sortField, sortValue] = (sort || '').split(',');
  if (!sortField || sortField !== fieldName) return 0;
  return sortDirectory[sortValue] || 0;
};

/**
 * Parse sort info from string to number
 * @param {object} store store object
 * @param {string} fieldName name of columns parse sort
 * @returns {string} return value for save on store
 */
export const parseSortParams = (store, fieldName) => {
  const value = sortDirectoryInverted[parseSorted(store, fieldName)];
  return `${fieldName},${value}`;
};
