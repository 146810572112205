<template>
  <mds-button-container>
    <info-link-action :link="link" />
  </mds-button-container>
</template>

<script>
import { MdsButtonContainer } from '@mds/button';
import InfoLinkAction from '@/components/collection/table/actions/InfoLinkAction';

export default {
  name: 'LinkActions',
  components: {
    MdsButtonContainer,
    InfoLinkAction,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
};
</script>
