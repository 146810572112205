<template>
  <div>
    <mds-button
      variation="icon-only"
      icon="info-circle"
      @click.stop="toggleModal"
    />
    <portal
      v-if="isModalVisible"
      to="modals"
    >
      <mds-modal
        v-model="isModalVisible"
        width="600px"
      >
        <mds-section
          border-position="bottom"
          bold
        >
          <template #mds-section-title>
            <span>Link Information</span>
          </template>
          <mds-table>
            <mds-tbody>
              <mds-tr>
                <mds-th class="table__header">
                  Domain:
                </mds-th>
                <mds-td>
                  {{ link.domain }}
                </mds-td>
              </mds-tr>
              <mds-tr>
                <mds-th class="table__header">
                  Index Type:
                </mds-th>
                <mds-td>
                  {{ link.indexType | formatIndexType }}
                </mds-td>
              </mds-tr>
              <mds-tr>
                <mds-th class="table__header">
                  Timed Out:
                </mds-th>
                <mds-td>
                  <status-tag
                    v-if="link.timedOut"
                    fstatus="TIMED_OUT"
                  />
                </mds-td>
              </mds-tr>
              <mds-tr>
                <mds-th>
                  Duration:
                </mds-th>
                <mds-td>
                  {{ link.durationSeconds }} (s)
                </mds-td>
              </mds-tr>
              <mds-tr>
                <mds-th>
                  Execution Count:
                </mds-th>
                <mds-td>
                  {{ link.executionCount }}
                </mds-td>
              </mds-tr>
              <mds-tr>
                <mds-th>
                  Error
                </mds-th>
                <mds-td>
                  {{ link.error }}
                </mds-td>
              </mds-tr>
            </mds-tbody>
          </mds-table>
        </mds-section>
      </mds-modal>
    </portal>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';
import { MdsTable, MdsTbody, MdsTh, MdsTr, MdsTd } from '@mds/data-table';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import StatusTag from '@/components/ui/StatusTag';

export default {
  name: 'InfoLink',
  components: {
    MdsButton,
    MdsTable,
    MdsTbody,
    MdsTh,
    MdsTr,
    MdsTd,
    MdsModal,
    MdsSection,
    StatusTag,
  },
  filters: {
    formatIndexType (index) {
      return index && index.split('_')[1];
    },
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    toggleModal () {
      this.isModalVisible = !this.isModalVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__header {
    width: 200px;
  }
}
</style>
