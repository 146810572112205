<template>
  <mds-layout-grid>
    <filter-section />
    <table-section />
  </mds-layout-grid>
</template>

<script>
import TableSection from '@/components/collection/table/TableSection';
import FilterSection from '@/components/collection/filter/FilterSection';

export default {
  name: 'CollectionPage',
  components: {
    TableSection,
    FilterSection,
  },
};
</script>
