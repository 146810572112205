<template>
  <div>
    <mds-button
      variation="icon-only"
      icon="download"
      text="Get Report"
      @click.stop="getReport(job.id)"
    />
    <mds-button
      v-show="false"
      ref="downloadReport"
      text="Download Report"
      :download="`report-${job.name}-${job.lastExecutedDate}.xlsx`"
      :href="reportUrl"
      @click.stop
    />
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';
import { getJobReport } from '@/services/api/job.service';

export default {
  name: 'DownloadReport',
  components: {
    MdsButton,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      reportUrl: null,
    };
  },
  methods: {
    async getReport (jobId) {
      const { data: report } = await getJobReport({ jobId });
      this.reportUrl = URL.createObjectURL(new Blob([report]));
      await this.$nextTick();
      this.$refs.downloadReport.$el.click();
      URL.revokeObjectURL(this.reportUrl);
      this.reportUrl = null;
    },
  },
};
</script>
