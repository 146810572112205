<template>
  <mds-row>
    <mds-col>
      <store-provider
        v-bind="$options.storeInfo"
        is-paged
      >
        <template v-slot="{ state: jobs }">
          <jobs-table
            :jobs="jobs('jobs')"
            :total-elements="jobs('totalElements')"
          />
        </template>
      </store-provider>
    </mds-col>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import JobsTable from '@/components/collection/table/jobs/JobsTable';
import { MODULE, COMMON } from '@/constants/store.constant';

export default {
  name: 'TableSection',
  components: {
    StoreProvider,
    JobsTable,
  },
  storeInfo: {
    module: MODULE.COLLECTION,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>
