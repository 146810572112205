<template>
  <div>
    <mds-button
      variation="icon-only"
      icon="create"
      text="Edit"
      @click.stop="toggleModal"
    />
    <portal
      v-if="isModalVisible"
      to="modals"
    >
      <mds-modal
        v-model="isModalVisible"
        width="600px"
      >
        <mds-form>
          <mds-select
            v-model="frequency"
            label="Frequency"
            :options="[
              { text: 'Daily', value: 'DAILY' },
              { text: 'Weekly', value: 'WEEKLY' },
              { text: 'Monthly', value: 'MONTHLY' },
              { text: 'Every four hours', value: 'EVERY_FOUR_HOURS' },
              { text: 'Every six hours', value: 'EVERY_SIX_HOURS' },
            ]"
          />
          <mds-button-container right-aligned>
            <mds-button
              variation="primary"
              type="button"
              text="Update"
              @click="patchJob(job.id); toggleModal()"
            />
          </mds-button-container>
        </mds-form>
      </mds-modal>
    </portal>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsModal from '@mds/modal';
import MdsSelect from '@mds/select';
import MdsForm from '@mds/form';
import { patchJob } from '@/services/api/job.service';

export default {
  name: 'EditJob',
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsModal,
    MdsSelect,
    MdsForm,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isModalVisible: false,
      frequency: '',
    };
  },
  methods: {
    async patchJob (id) {
      await patchJob({ id, frequency: this.frequency });
    },
    toggleModal () {
      this.isModalVisible = !this.isModalVisible;
    },
  },
};
</script>
