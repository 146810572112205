export const TABLE_HEADERS = [
  {
    fieldName: 'translatedName',
    text: 'Company Name',
    style: {
      width: '15%',
    },
    sortable: true,
    sortableName: 'provider.translatedName',
  },
  {
    fieldName: 'companyId',
    text: 'Company Id',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'provider.companyId',
  },
  {
    fieldName: 'frequency',
    text: 'Frequency',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'frequency',
  },
  {
    fieldName: 'lastExecutedDate',
    text: 'Last Execution',
    style: {
      width: '15%',
    },
    sortable: true,
    sortableName: 'lastExecutedDate',
  },
  {
    fieldName: 'nextExecutionDate',
    text: 'Next Execution',
    style: {
      width: '15%',
    },
    sortable: true,
    sortableName: 'nextExecutionDate',
  },
  {
    fieldName: 'homePage',
    text: 'Links',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'coverage',
    text: 'Coverage',
    style: {
      width: '7%',
    },
  },
  {
    fieldName: 'executionCount',
    text: 'Executions',
    style: {
      width: '8%',
    },
    sortable: true,
    sortableName: 'jobProviderLinks.executionCount',
  },
  {
    fieldName: 'newFilesFoundCount',
    text: 'New Files',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'jobProviderLinks.newFilesFoundCount',
  },
  {
    fieldName: 'downloadedFilesCount',
    text: 'Files',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'jobProviderLinks.downloadedFilesCount',
  },
  {
    fieldName: 'fileErrors',
    text: 'Errors',
    style: {
      width: '10%',
    },
    sortable: true,
    sortableName: 'fileErrors',
  },
  {
    fieldName: 'status',
    sortable: true,
    text: 'Status',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'actions',
    text: 'Actions',
    style: {
      width: '10%',
    },
  }
];

export const VISITED_LINKS_HEADERS = [
  {
    fieldName: 'link',
    text: 'Link',
    sortable: true,
  },
  {
    fieldName: 'downloadedFilesCount',
    text: 'Files Found',
    sortable: true,
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'fileErrors',
    text: 'Errors',
    style: {
      width: '10%',
    },
    sortable: true,
  },
  {
    fieldName: 'statusCode',
    text: 'Status Code',
    sortable: true,
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'lastIndexedDate',
    text: 'Last Indexation',
    sortable: true,
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'active',
    text: 'Active',
    // sortable: true,
    style: {
      width: '10%',
    },
  }
];

export const FILE_LINKS_HEADERS = [
  {
    fieldName: 'uuid',
    text: 'Id',
    sortable: true,
  },
  {
    fieldName: 'parentLink',
    text: 'Parent Link',
    sortable: true,
  },
  {
    fieldName: 'linkText',
    text: 'Link Text',
    sortable: true,
  },
  {
    fieldName: 'pageTitle',
    text: 'Page Title',
    sortable: true,
  },
  {
    fieldName: 'headers',
    text: 'Headers',
    sortable: true,
  },
  {
    fieldName: 'extension',
    text: 'Extension',
    sortable: true,
  },
  {
    fieldName: 'uploadDate',
    text: 'Last Upload',
    sortable: true,
  },
  {
    fieldName: 'error',
    text: 'Error',
    sortable: true,
  },
  {
    fieldName: 'actions',
    text: 'Actions',
  }
];

export const PENDING_LINKS_HEADERS = [
  {
    fieldName: 'link',
    text: 'Link',
    sortable: true,
  },
  {
    fieldName: 'lastPendingDate',
    text: 'Last Pending',
    sortable: true,
  }
];

export const ERROR_LINKS_HEADERS = [
  {
    fieldName: 'link',
    text: 'Link',
    sortable: true,
  },
  {
    fieldName: 'error',
    text: 'Error',
    sortable: true,
    style: {
      width: '50%',
    },
  },
  {
    fieldName: 'statusCode',
    text: 'Status Code',
    sortable: true,
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'lastIndexedDate',
    text: 'Last Indexation',
    sortable: true,
    style: {
      width: '15%',
    },
  }
];

export const LINK_TYPE = {
  VisitedLinksTab: 'visited/success',
  PendingLinksTab: 'pending',
  ErrorLinksTab: 'visited/error',
  FileLinksTab: 'file',
};

export const FILTER_RULES = {
  statusCode: 'required|numeric|max:3',
  filesFoundCount: 'required|numeric|max:5',
  link: 'required',
  parentLink: 'required',
  linkText: 'required',
  pageTitle: 'required',
  headers: 'required',
  extension: 'required',
};

export const OPTIONS_FILTER = {
  VisitedLinksTab: [
    { text: 'Link', value: 'link' },
    { text: 'Files Found', value: 'filesFoundCount' },
    { text: 'Status', value: 'statusCode' },
    { text: 'Last Indexed Date', value: 'lastIndexedDate' }
  ],
  FileLinksTab: [
    { text: 'Id', value: 'uuid' },
    { text: 'Parent Link', value: 'parentLink' },
    { text: 'Link', value: 'linkText' },
    { text: 'Page Title', value: 'pageTitle' },
    { text: 'Header', value: 'headers' },
    { text: 'Extension', value: 'extension' },
    { text: 'Upload Date', value: 'uploadDate' }
  ],
  PendingLinksTab: [
    { text: 'Link', value: 'link' },
    { text: 'Status', value: 'statusCode' },
    { text: 'Last Pending Date', value: 'lastPendingDate' }
  ],
  ErrorLinksTab: [
    { text: 'Link', value: 'link' },
    { text: 'Files Found', value: 'filesFoundCount' },
    { text: 'Status', value: 'statusCode' },
    { text: 'Last Indexed Date', value: 'lastIndexedDate' }
  ],
};

export const REFRESH_JOBS_INTERVAL_IN_SECONDS = 10;
