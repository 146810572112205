<template>
  <div>
    <mds-row>
      <mds-col :push="10">
        <h4 class="jobs_table__totalelements">
          Total Elements: {{ totalElements }}
        </h4>
      </mds-col>
    </mds-row>
    <mds-table row-hover>
      <mds-thead>
        <mds-th
          v-for="header in $options.headers"
          :key="header.fieldName"
          :style="header.style"
          :sortable="header.sortable"
          :sorted="isSorted(header.sortableName)"
          @mds-th-sort-changed="handleSortEvent(header.sortableName, $event)"
        >
          {{ header.text }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <template v-for="job in jobsCopy">
          <mds-tr
            :key="job.id"
            :expanded="!!job.expanded"
            type="expandable"
            @click.native="toggleRow(job)"
          >
            <mds-td
              v-for="header in $options.headers"
              :key="header.fieldName"
              :style="header.style"
            >
              <template v-if="header.fieldName === 'translatedName'">
                {{ job.provider[header.fieldName] }}
              </template>
              <template v-else-if="header.fieldName === 'companyId'">
                <router-link
                  :to="{
                    name: $options.route.PROVIDER,
                    params: { id: job.provider[header.fieldName]}
                  }"
                  target="_blank"
                >
                  {{ job.provider[header.fieldName] }}
                </router-link>
              </template>
              <template v-else-if="header.fieldName === 'lastExecutedDate'">
                {{ job[header.fieldName] | formatDate }}
              </template>
              <template v-else-if="header.fieldName === 'nextExecutionDate'">
                {{ job[header.fieldName] | formatDate }}
              </template>
              <template v-else-if="header.fieldName === 'frequency'">
                {{ job[header.fieldName] | textFormatter }}
              </template>
              <template v-else-if="header.fieldName === 'coverage'">
                {{ job[header.fieldName] }}
              </template>
              <template v-else-if="header.fieldName === 'homePage'">
                {{ job.provider.links.length }}
              </template>
              <template v-else-if="header.fieldName === 'executionCount'">
                {{ job.jobProviderLinks | sumExecutionCount }}
              </template>
              <template v-else-if="header.fieldName === 'newFilesFoundCount'">
                {{ job.jobProviderLinks | sumNewFilesFoundCount }}
              </template>
              <template v-else-if="header.fieldName === 'downloadedFilesCount'">
                {{ job.jobProviderLinks | sumProviderDownloadedFilesCount }}
              </template>
              <template v-else-if="header.fieldName === 'fileErrors'">
                {{ job.jobProviderLinks | sumProviderLinksErrors }}
              </template>
              <template v-else-if="header.fieldName === 'status'">
                <status-tag :status="job.status" />
              </template>
              <template v-else-if="header.fieldName === 'actions'">
                <table-actions :job="job" />
              </template>
              <template v-else>
                {{ job[header.fieldName] || '—' }}
              </template>
            </mds-td>
          </mds-tr>
          <mds-tr
            v-if="!!job.expanded"
            :key="job.provider.homePage"
            type="container"
          >
            <mds-td :colspan="$options.headers.length">
              <links-table :links="jobLinks" />
            </mds-td>
          </mds-tr>
        </template>
      </mds-tbody>
    </mds-table>
  </div>
</template>

<script>
import StatusTag from '@/components/ui/StatusTag';
import TableActions from '@/components/collection/table/actions/TableActions';
import { formatDate, textFormatter } from '@/utils/global.util';
import { parseSorted, parseSortParams } from '@/utils/sort.util';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { TABLE_HEADERS } from '@/constants/jobs.constant';
import { MODULE, COMMON } from '@/constants/store.constant';
import ROUTE from '@/constants/routes.constant';
import LinksTable from '@/components/collection/table/links/LinksTable';
import { getProviderLinksByJobId } from '@/services/api/job.service';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'JobsTable',
  components: {
    StatusTag,
    MdsTable,
    TableActions,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    LinksTable,
  },
  filters: {
    formatDate,
    textFormatter,
    sumProviderDownloadedFilesCount (files) {
      return files.reduce((accumulator, { downloadedFilesCount }) => accumulator + downloadedFilesCount, 0);
    },
    sumProviderFilesFoundCount (files) {
      return files.reduce((accumulator, { filesFoundCount }) => accumulator + filesFoundCount, 0);
    },
    sumProviderLinksErrors (files) {
      const { sumFilesFoundCount, sumDownloadedFilesCount } = files.reduce((acc, { filesFoundCount = 0, downloadedFilesCount = 0 }) => {
        if (filesFoundCount > 0) {
          acc.sumFilesFoundCount += filesFoundCount;
          acc.sumDownloadedFilesCount += downloadedFilesCount;
        }
        return acc;
      }, { sumFilesFoundCount: 0, sumDownloadedFilesCount: 0 });

      return sumFilesFoundCount === 0 ? 0 : sumFilesFoundCount - sumDownloadedFilesCount;
    },
    sumNewFilesFoundCount (files) {
      return files.reduce((accumulator, { newFilesFoundCount }) => accumulator + newFilesFoundCount, 0);
    },
    sumExecutionCount (files) {
      return files.reduce((accumulator, { executionCount }) => accumulator + executionCount, 0);
    },
  },
  props: {
    jobs: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      jobsCopy: cloneDeep(this.jobs),
      jobLinks: [],
      pooling: null,
    };
  },
  watch: {
    jobs: {
      handler (jobs) {
        this.jobsCopy = cloneDeep(jobs);
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    async handleSortEvent (fieldName) {
      const value = parseSortParams(this.$store.state[MODULE.COLLECTION], fieldName);
      this.$store.commit(`${MODULE.COLLECTION}/${COMMON.UPDATE_PARAM}`, { name: 'sort', value });
      await this.$store.dispatch(`${MODULE.COLLECTION}/${COMMON.FETCH_DATA}`, { shouldReset: true });
    },
    isSorted (fieldName) {
      return parseSorted(this.$store.state[MODULE.COLLECTION], fieldName);
    },
    async toggleRow ({ id, expanded }) {
      if (!expanded) {
        const { data: jobLinks } = await getProviderLinksByJobId(id);
        this.jobLinks = jobLinks;
      }

      const updatedJobs = this.jobsCopy.map((job) => ({ ...job, expanded: job.id === id && !job.expanded }));
      this.jobsCopy = updatedJobs;
    },
  },
  storeInfo: {
    module: MODULE.COLLECTION,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
  headers: TABLE_HEADERS,
  route: ROUTE,
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

.jobs_table {
  &__totalelements {
    @include mds-eyebrow-heading();
  }
}
</style>
